/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "rrgsystems",
  description:
    "A passionate Team who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Ashutosh Hathidara Portfolio",
    type: "website",
    url: "http://ashutoshhathidara.com/",
  },
};

//Home Page
const greeting = {
  title: "rrgystems",
  logo_name: "AshutoshHathidara",
  nickname: "layman_brother",
  subTitle:
    "A passionate Team who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  resumeLink:
    "https://drive.google.com/file/d/1FL_wydnPF2SWCdw2goYAgZ1KKBx8TpcD/view?usp=sharing",
  portfolio_repository: "https://github.com/ashutosh1919/masterPortfolio",
  githubProfile: "https://github.com/ashutosh1919",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"


  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/rrgsystems-rrgsystems-846044280/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },

  {
    name: "Gmail",
    link: "mailto:rrgsystem23@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Twitter",
    link: "elmax.rw",
    fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
    backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  },
  {
    name: "Facebook",
    link: "https://web.facebook.com/Readingtechnology",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },

];

const skills = {
  data: [
    {
      title: "Mobile Apps development",
      fileName: "DataScienceImg",
      skills: [
        " Developing highly scalable production ready models for various deeplearning and statistical use cases",
        " Experience of working with Phone Vision  ",
        " Complex quantitative modelling for dynamic forecasting and time series analysis",
      ],
      softwareSkills: [
        {
          skillName: "iPhone",
        imageSrc: "iphone.png",
        },
         {
                  skillName: "Android",
                imageSrc: "android.jpeg",
                },
         {
                  skillName: "Windowx",
                imageSrc: "windo.png",
                },
        {
          skillName: "USSD",
         imageSrc: "ussd.png",
        },

      ],
    },
    {
      title: "Desktop apps Development",
      fileName: "FullStackImg",
      skills: [
        " Building responsive website front end using React-Redux",
        " Developing Desktop applications using Flutter, React Native and solo android apps using Kotlin",
        " Creating application backend in Node, Express & Flask",
      ],
      softwareSkills: [
       {
                                skillName: "Mac",
                              imageSrc: "mac.jpeg",
                              },
         {
                          skillName: "Linux",
                        imageSrc: "linux.png",
                        },
        {
                         skillName: "Windowx",
                       imageSrc: "windo.png",
                       },

      ],
    },
    {
      title: "Web app development",
      fileName: "CloudInfraImg",
      skills: [
        " Building responsive website front end using React-Redux",
        " Maintaining websites on virtual machine instances along with integration of databases",
        " Develop web application using html5, Java script, css, php",

      ],
      softwareSkills: [
         {
            skillName: "Java",
            imageSrc: "java.jpeg",
          },
        {
          skillName: "Api",
          imageSrc: "api.jpeg",

        },
        {
                  skillName: "Python",
                  imageSrc: "Python.png",

                },
         {
                           skillName: "Ajax",
                           imageSrc: "ajax.png",

                         },

      ],
    },
    {
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        " Designing highly attractive user interface for mobile and web applications",
        " Customizing logo designs and building logos from scratch",
        " Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Wordpress",
          imageSrc: "wordpress.png",

        },
        {
          skillName: "Blog",
          imageSrc: "blog.png",

        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",

        },
        {
          skillName: "Inkscape",
          fontAwesomeClassname: "simple-icons:inkscape",

        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [

  ],
};

const degrees = {
  degrees: [
    {
      title: "Project Manager",
      subtitle: "Dieudonne UWAMBAJIMANA",
      logo_path: "dodos.png",
      alt_name: "IIITDM Kurnool",

      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
        "⚡ I have proper knowledge in high level programing language such java, php, vb, c,c++ .",
        "⚡ I have proper knowledge of Installed PLC systems including uploading/downloading ",
      ],

    },
    {
      title: "Software Engineer",
      subtitle: "Theogene NGABONZIZA",
      logo_path: "theo-mod.png",
      alt_name: "Indiana University Bloomington",

      descriptions: [
        "⚡ I have taken varity of courses related to Artificial Intelligence which correspond to Explainable AI,  Computer Vision etc.",
        "⚡ Apart from this, I have proper knowledge in high level programing language such java, php, vb, c,c++, Flutter, VB.net  .",
        "⚡ I have proper knowledge in computer workstation security, server security, network security by applying company security policy and other related procedures",
      ],

    },
     {
          title: "Data Analyst",
          subtitle: "XXXXXXXXXXX",
          logo_path: "Dataanalyst.png",
          alt_name: "Indiana University Bloomington",

          descriptions: [
            "⚡ I have taken varity of courses related to Artificial Intelligence which correspond to Explainable AI, Graph Machine Learning, Computer Vision etc.",
            "⚡ Apart from this, I have also done research assistantship. As part of it, I have worked on creating new algorithms in Graph ML and Network Science.",
            "⚡ During my time at university, I was also associated with multimedia department. As part of it, I have worked on some documentry films and interviews.",
          ],

        },
         {
              title: "Software Designer",
              subtitle: "XXXXXXXXXXXXX",
              logo_path: "softdesign.png",
              alt_name: "Indiana University Bloomington",

              descriptions: [
                "⚡ I have taken varity of courses related to Artificial Intelligence which correspond to Explainable AI, Graph Machine Learning, Computer Vision etc.",
                "⚡ Apart from this, I have also done research assistantship. As part of it, I have worked on creating new algorithms in Graph ML and Network Science.",
                "⚡ During my time at university, I was also associated with multimedia department. As part of it, I have worked on some documentry films and interviews.",
              ],

            },
          {
               title: "Database Administrator",
               subtitle: "YYYYYYYYYYYYY",
               logo_path: "dbadmin.png",
               alt_name: "Indiana University Bloomington",

               descriptions: [
                  "⚡ I have taken varity of courses related to Artificial Intelligence which correspond to Explainable AI, Graph Machine Learning, Computer Vision etc.",
                  "⚡ Apart from this, I have also done research assistantship. As part of it, I have worked on creating new algorithms in Graph ML and Network Science.",
                  "⚡ During my time at university, I was also associated with multimedia department. As part of it, I have worked on some documentry films and interviews.",
                 ],

             },

  ],
};

const certifications = {
  certifications: [

  ],
};

// Experience Page
const experience = {
  title: "Download",
  subtitle: "Are you interested? Download some of our Product here",
  description:
    "We have worked with many evolving startups as ML and DL Developer, Designer and Software Architect. We have also worked with some well established companies mostly as AI Developer. We love organising events and that is why We are also involved with many opensource communities as a representative.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Mobile Application",
      experiences: [
        {
          title: "Athlete",
          company: "Download",
          company_url: "https://play.google.com/store/apps/details?id=com.delivery.Knitman",
          logo_path: "knitman.jpeg",

          duration: "Developed 2023",
          location: "Kigali, Rwanda",
          description:
            "Is a Mobile Application: is The Athlete app enables you to input information directly into the Kitman app in order to make courier transport flexible . Your customers can request information about the workload with riders .",
          color: "#0879bf",
        },


      ],
    },
    {
      title: "Desktop Applications ",
      experiences: [
        {
          title: "Sbm",
          company: "Download For Mac.",
          company_url: "https://rrgsystems.rw/admin.zip",
          logo_path: "apple.jpeg",
          duration: "Developed Dec 2022",
          location: "Kigali, Rwanda",
          description:
            ". Sbm (System business management)is a portfolio of intelligent business applications that delivers superior operational efficiency and breakthrough customer experiences enabling businesses to become more agile and reduce complexity without increasing costs.",
          color: "#000000",
        },
               {
                  title: "Sbm",
                  company: "Download for windows.",
                  company_url: "https://rrgsystems.rw/sbm.zip",
                  logo_path: "sbm.png",
                  duration: "Developed Dec 2022",
                  location: "Kigali, Rwanda",
                  description:
                    ". Sbm (System business management)is a portfolio of intelligent business applications that delivers superior operational efficiency and breakthrough customer experiences enabling businesses to become more agile and reduce complexity without increasing costs.",
                  color: "#000000",
                },


      ],
    },
    {
      title: "Web Applications",
      experiences: [
        {
          title: "elmax.rw",
          company: "Download",
          company_url: "https://elmax.rw/",
          logo_path: "elmax.png",
          duration: "Developed: April 2021",
          location: "Kigali,Rwanda",
          description:
            "elmax (elmax.rw) enable businesses to transform the way they market, sell and operate and improve their efficiencies. We provide the technology infrastructure and marketing reach to help merchants, brands, retailers and other businesses to leverage the power of new technology to engage with their users and customers and operate in a more efficient way. We also empower enterprises with our leading cloud infrastructure and services and enhanced work collaboration capabilities to facilitate their digital transformation and to support the growth of their businesses.",
          color: "#4285F4",
        },




      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "Our projects makes use of vast variety of latest technology tools. Our best experience is to create Softwares projects  using different languages.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "We have worked on  few project.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzQ=",
      name: "Mobile Application ",
      createdAt: "",
      description: "Athlete ",
      url:
        "#",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzi=",
      name: "Desktop Application Paper",
      createdAt: "",
      description: "SBM ",

    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNze=",
      name: "Web application Paper",
      createdAt: "",
      description: "elmax.rw ",
      url:
        "https://elmax.rw/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzt=",
      name: "Website",
      createdAt: "",
      description: "readingtechnology.rw ",
      url:
        "https://readingtechnology.rw/",
    },
{
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzt=",
      name: "Website",
      createdAt: "",
      description: "reaplereels.rw ",
      url:
        "https://www.ripplereels.com",
    },
    {
          id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzt=",
          name: "Website ",
          createdAt: "",
          description: "rrgsystems.rw ",
          url:
            "http://rrgsystem.elmax.rw/",
        },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Us",
    profile_image_path: "log.png",
    description:
      "We are available on almost every social media. You can message us, help you to develop for you develop modern applications such as  web app,mobile app desktop app.",
   link: "#",
  },

  blogSection: {
    title: "Sites",
    subtitle:
      " We would like to receive any  suggestion idea and comment that can make us to improve our Productivity.",
    link: "#",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "Kigali, Makuza, Peace Plazza, Ground Floor,Block C ,Nyarugenge District.KN 48 Street",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://www.google.com/maps/place/Makuza+Peace+Plaza/@-1.9465602,30.0569905,17z/data=!3m1!4b1!4m6!3m5!1s0x19dca4240db7b3f5:0x5256fd511623ef15!8m2!3d-1.9465656!4d30.0595654!16s%2Fg%2F11c1nfd6s8?entry=ttu",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
