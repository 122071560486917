import React from "react";
import "./LoaderLogo.css";

class LogoLoader extends React.Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="1042.000000pt" height="628.000000pt" viewBox="0 0 1042.000000 628.000000"
 preserveAspectRatio="xMidYMid meet">
<metadata>
Created by potrace 1.16, written by Peter Selinger 2001-2019
</metadata>
<g transform="translate(0.000000,628.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M20 3120 l0 -3120 5185 0 5185 0 0 3120 0 3120 -5185 0 -5185 0 0
-3120z m7111 2082 c241 -64 396 -225 459 -477 27 -107 50 -298 50 -417 l0 -98
-309 0 -309 0 -4 188 c-4 204 -13 245 -62 277 -63 41 -175 26 -225 -31 -52
-58 -53 -73 -49 -934 l3 -795 25 -49 c36 -71 67 -91 145 -91 74 0 109 20 138
77 29 59 37 140 37 386 l0 232 -85 0 -85 0 0 250 0 250 390 0 390 0 0 -855 0
-855 -127 0 -128 0 -54 125 c-48 109 -71 147 -71 116 0 -32 -136 -157 -213
-197 -119 -61 -333 -82 -492 -49 -213 45 -362 186 -441 417 -59 174 -59 177
-59 1083 0 749 2 838 17 907 38 169 105 297 204 387 93 85 180 127 334 162
118 26 407 21 521 -9z m-3466 -15 c174 -50 278 -148 335 -316 35 -106 52 -250
53 -456 0 -220 -13 -298 -68 -412 -41 -83 -89 -129 -183 -175 l-63 -31 49 -17
c69 -25 84 -34 129 -83 49 -53 83 -138 91 -232 4 -38 6 -325 4 -637 l-3 -568
-310 0 -309 0 0 405 c0 459 -8 424 88 378 89 -43 197 -158 253 -268 25 -50 52
-114 59 -143 13 -47 16 -52 42 -52 18 0 42 13 69 37 38 34 41 41 37 78 -12
102 -94 251 -194 350 -80 80 -196 145 -295 165 l-56 11 -6 134 c-8 203 -35
239 -186 252 l-71 6 0 -215 0 -215 -83 -41 c-201 -99 -349 -312 -334 -478 3
-34 17 -85 32 -116 31 -65 130 -166 234 -237 l75 -51 -282 0 -282 0 0 1476 0
1475 558 -3 c488 -4 564 -6 617 -21z m1794 -1 c141 -37 251 -125 310 -249 16
-34 40 -109 52 -167 20 -92 23 -135 23 -340 1 -196 -2 -246 -17 -300 -44 -160
-109 -245 -230 -301 l-71 -32 49 -14 c121 -36 190 -117 221 -262 15 -73 20
-659 7 -1049 l-6 -212 -308 0 -308 0 -3 623 c-3 579 -4 624 -21 655 -23 42
-68 62 -158 69 l-69 6 0 -677 0 -676 -325 0 -325 0 0 1476 0 1475 558 -4 c481
-3 566 -6 621 -21z m-2329 -2446 c0 -297 -2 -330 -16 -322 -8 5 -55 45 -104
90 -142 132 -177 228 -129 357 26 69 95 142 169 178 30 14 61 26 68 27 9 0 12
-73 12 -330z m2862 -247 c41 -45 58 -76 58 -104 0 -14 -25 -106 -55 -204 -30
-98 -53 -180 -51 -182 2 -2 128 6 281 17 219 17 280 18 292 9 20 -17 43 -64
43 -88 0 -23 -7 -24 -345 -46 -126 -9 -249 -18 -272 -21 l-43 -5 -24 -82 c-94
-312 -181 -729 -231 -1099 -4 -30 -11 -48 -20 -48 -17 0 -73 64 -87 99 -27 71
83 679 191 1054 l20 67 -102 -7 c-169 -12 -168 -12 -194 26 -13 19 -23 44 -23
57 0 28 14 31 175 39 188 10 179 6 199 78 52 192 142 467 151 467 6 0 23 -12
37 -27z m-2696 -206 c77 -51 195 -129 264 -173 291 -189 473 -379 547 -571 35
-91 44 -265 18 -363 -51 -199 -219 -396 -420 -494 -124 -61 -211 -80 -370 -80
-213 0 -365 43 -531 153 -196 129 -308 302 -321 496 -15 238 151 503 463 738
93 71 128 87 146 69 21 -21 -9 -58 -105 -130 -111 -84 -238 -215 -287 -297
-76 -129 -100 -274 -64 -396 66 -222 290 -413 570 -485 297 -77 581 8 718 214
170 256 96 542 -210 820 -162 147 -248 210 -622 457 -4 3 53 135 59 135 4 0
69 -42 145 -93z m2124 -445 c0 -36 -58 -90 -114 -106 -36 -11 -63 -26 -80 -47
-48 -57 -47 -105 5 -164 l31 -34 101 51 c138 69 290 129 300 119 11 -11 -14
-60 -45 -87 -12 -12 -68 -41 -123 -64 -166 -70 -177 -77 -164 -102 19 -36 14
-109 -10 -157 -30 -59 -115 -141 -172 -169 -52 -25 -115 -28 -164 -8 -62 26
-72 85 -27 157 28 43 126 144 158 162 14 8 14 11 1 25 -8 10 -22 31 -31 49
-62 124 76 332 253 379 65 18 81 17 81 -4z m2600 0 c0 -36 -58 -90 -113 -106
-82 -24 -129 -87 -113 -151 3 -13 20 -40 37 -60 l31 -34 101 51 c138 69 290
129 300 119 11 -11 -14 -60 -45 -87 -12 -12 -68 -41 -123 -64 -166 -70 -177
-77 -164 -102 19 -35 13 -110 -11 -158 -29 -57 -114 -141 -171 -168 -52 -25
-115 -28 -164 -8 -101 43 -61 159 103 298 26 21 39 39 33 43 -21 12 -51 84
-51 119 0 125 129 274 269 312 65 18 81 17 81 -4z m-1152 -170 c29 -52 43
-118 50 -246 l7 -110 110 125 c61 68 124 142 141 163 21 27 36 37 47 33 29
-11 54 -79 61 -167 14 -160 37 -230 76 -230 11 0 45 27 77 62 161 172 187 198
191 193 13 -12 -66 -157 -135 -248 -99 -129 -198 -144 -252 -39 -21 41 -41
135 -41 191 l0 36 -32 -40 c-17 -22 -89 -98 -160 -169 l-129 -130 -20 21 c-38
38 -47 86 -32 166 7 40 12 112 11 162 l-3 90 -133 -181 c-81 -110 -143 -186
-160 -193 -24 -12 -29 -10 -50 16 -12 15 -25 40 -29 56 -3 15 -8 27 -10 27 -3
0 -38 -22 -79 -49 -145 -97 -264 -121 -341 -68 -93 65 -112 170 -52 291 62
125 212 262 299 273 35 5 41 3 51 -20 32 -71 -80 -268 -200 -351 l-42 -29 30
-30 c60 -61 171 -45 287 41 37 27 42 36 48 84 7 56 61 276 69 285 10 9 26 -7
53 -52 l25 -44 -22 -82 c-17 -64 -19 -83 -9 -89 7 -4 10 -15 7 -24 -12 -31 1
-15 43 51 68 109 199 273 214 267 7 -3 22 -22 34 -42z m-1953 1 c14 -21 25
-46 25 -57 0 -10 -16 -60 -36 -110 -101 -254 -157 -605 -190 -1179 -5 -100 -9
-118 -22 -115 -29 6 -62 67 -62 117 0 191 58 784 87 886 4 13 -13 3 -49 -31
-103 -98 -202 -124 -280 -76 -91 56 -92 190 -3 415 21 53 44 97 51 97 7 0 25
-16 40 -35 32 -43 31 -57 -27 -222 -49 -141 -47 -155 20 -135 51 16 165 106
216 171 51 65 140 213 161 269 9 23 23 42 30 42 7 0 25 -17 39 -37z"/>
<path d="M3135 4657 c-3 -7 -4 -131 -3 -277 l3 -265 103 0 104 0 33 36 c45 50
58 117 53 288 -4 156 -17 193 -73 217 -45 18 -214 20 -220 1z"/>
<path d="M4930 4389 l0 -281 99 4 c118 4 145 18 176 94 16 40 19 74 20 194 0
241 -21 270 -200 270 l-95 0 0 -281z"/>
<path d="M5119 1317 c-48 -36 -73 -76 -63 -101 3 -8 21 -20 40 -26 62 -21 124
9 124 59 0 29 -31 93 -47 98 -6 2 -30 -11 -54 -30z"/>
<path d="M7719 1317 c-48 -36 -73 -76 -63 -101 3 -8 21 -20 40 -26 62 -21 124
9 124 59 0 29 -31 93 -47 98 -6 2 -30 -11 -54 -30z"/>
</g>

        <defs>
          <style
            dangerouslySetInnerHTML={{
              __html: `

      .signature1{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 0.5s;
			-o-animation: dash 1s linear forwards 0.5s;
			-moz-animation: dash 1s linear forwards 0.5s;
			animation: dash 1s linear forwards 0.5s;
		}
		.signature2{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 0.7s;
			-o-animation: dash 1s linear forwards 0.7s;
			-moz-animation: dash 1s linear forwards 0.7s;
			animation: dash 1s linear forwards 0.7s;
		}
		.signature3{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 0.9s;
			-o-animation: dash 1s linear forwards 0.9s;
			-moz-animation: dash 1s linear forwards 0.9s;
			animation: dash 1s linear forwards 0.9s;
		}
		.signature4{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 1.1s;
			-o-animation: dash 1s linear forwards 1.1s;
			-moz-animation: dash 1s linear forwards 1.1s;
			animation: dash 1s linear forwards 1.1s;
		}
		.signature5{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 1.3s;
			-o-animation: dash 1s linear forwards 1.3s;
			-moz-animation: dash 1s linear forwards 1.3s;
			animation: dash 1s linear forwards 1.3s;
		}
		.signature6{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 1.5s;
			-o-animation: dash 1s linear forwards 1.5s;
			-moz-animation: dash 1s linear forwards 1.5s;
			animation: dash 1s linear forwards 1.5s;
		}
		.signature7{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 1.7s;
			-o-animation: dash 1s linear forwards 1.7s;
			-moz-animation: dash 1s linear forwards 1.7s;
			animation: dash 1s linear forwards 1.7s;
		}
		.signature8{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 1.9s;
			-o-animation: dash 1s linear forwards 1.9s;
			-moz-animation: dash 1s linear forwards 1.9s;
			animation: dash 1s linear forwards 1.9s;
		}
		.signature9{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 2.1s;
			-o-animation: dash 1s linear forwards 2.1s;
			-moz-animation: dash 1s linear forwards 2.1s;
			animation: dash 1s linear forwards 2.1s;
		}
		.signature10{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 2.3s;
			-o-animation: dash 1s linear forwards 2.3s;
			-moz-animation: dash 1s linear forwards 2.3s;
			animation: dash 1s linear forwards 2.3s;
		}
		.signature11{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 2.5s;
			-o-animation: dash 1s linear forwards 2.5s;
			-moz-animation: dash 1s linear forwards 2.5s;
			animation: dash 1s linear forwards 2.5s;
		}
		.signature12{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 2.7s;
			-o-animation: dash 1s linear forwards 2.7s;
			-moz-animation: dash 1s linear forwards 2.7s;
			animation: dash 1s linear forwards 2.7s;
		}
		.signature13{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 2.9s;
			-o-animation: dash 1s linear forwards 2.9s;
			-moz-animation: dash 1s linear forwards 2.9s;
			animation: dash 1s linear forwards 2.9s;
		}
		.signature14{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 3.1s;
			-o-animation: dash 1s linear forwards 3.1s;
			-moz-animation: dash 1s linear forwards 3.1s;
			animation: dash 1s linear forwards 3.1s;
		}
		.signature15{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 3.3s;
			-o-animation: dash 1s linear forwards 3.3s;
			-moz-animation: dash 1s linear forwards 3.3s;
			animation: dash 1s linear forwards 3.3s;
		}

		.signature{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 6s linear forwards 0.5s;
			-o-animation: dash 6s linear forwards 0.5s;
			-moz-animation: dash 6s linear forwards 0.5s;
			animation: dash 6s linear forwards 0.5s;
		}

		.letter{
			opacity: 0;
			-webkit-animation: fadein 2s linear forwards 2.5s;
			-o-animation: fadein 2s linear forwards 2.5s;
			-moz-animation: fadein 2s linear forwards 2.5s;
			animation: fadein 2s linear forwards 2.5s;
		}

		@-webkit-keyframes fadein{
			from{
				opacity: 0;
			}
			to{
				opacity: 1;
			}
		}

      .myHexagon{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 4s linear forwards 0.5s;
			-o-animation: dash 4s linear forwards 0.5s;
			-moz-animation: dash 4s linear forwards 0.5s;
			animation: dash 4s linear forwards 0.5s;
		}

		@-webkit-keyframes dash{
			from{
				stroke-dashoffset: 800;
			}
			to{
				stroke-dashoffset: 0;
			}
		}
    `,
            }}
          />
        </defs>
      </svg>
    );
  }
}

export default LogoLoader;
