import React from "react";
import {
Box,
Container,
Row,
Column,
FooterLink,
Heading,
} from "./FooterStyles";
import Header from "../../components/header/Header";
import { NavLink, Link } from "react-router-dom";

const Footer = () => {
return (
	<Box>
	<h1 style={{ color: "white",
				textAlign: "center",
				marginTop: "-85px" }}>
	</h1>
	<Container>
		<Row>
		<Column>
			<Heading>Teams</Heading>
			<FooterLink href ="education#/education" tag={Link}>Developers</FooterLink>
			<FooterLink href="education#/education" tag={Link}>QA</FooterLink>
			<FooterLink href="education#/education" tag={Link}>Devops</FooterLink>
			<FooterLink href="education#/education" tag={Link}>Designers</FooterLink>
		</Column>
		<Column>
			<Heading>Download</Heading>
			<FooterLink href="experience#/experience">Mobile Applications</FooterLink>
			<FooterLink href="experience#/experience">Desktop Applications</FooterLink>
			<FooterLink href="experience#/experience">Web Applications</FooterLink>
		</Column>
		<Column>
			<Heading>Projects</Heading>
			<FooterLink href ="https://play.google.com/store/apps/details?id=com.delivery.Knitman">Athlete</FooterLink>
			<FooterLink href="https://rrgsystems.rw/admin.zip">Sbm</FooterLink>
			<FooterLink href="https://elmax.rw/">Elmax</FooterLink>
			<FooterLink href="https://www.ripplereels.com/">Riplereels</FooterLink>
		</Column>
		<Column>
			<Heading>Contact Us</Heading>
			<FooterLink href="elmax.rw">
			<i className="fab fa-facebook-f">
				<span style={{ marginLeft: "10px" }}>
				Facebook
				</span>
			</i>
			</FooterLink>
			<FooterLink href="elmax.rw">
			<i className="fab fa-instagram">
				<span style={{ marginLeft: "10px" }}>
				Instagram
				</span>
			</i>
			</FooterLink>
			<FooterLink href="elmax.rw">
			<i className="fab fa-twitter">
				<span style={{ marginLeft: "10px" }}>
				Twitter
				</span>
			</i>
			</FooterLink>
			<FooterLink href="https://elmax.rw/">
			<i className="fab fa-youtube">
				<span style={{ marginLeft: "10px" }}>
				Youtube
				</span>
			</i>
			</FooterLink>
		</Column>
		</Row>
	</Container>
	</Box>
);
};
export default Footer;

